@font-face {
  font-family: "GT-Alpina";
  src: url("./assets/fonts/GT-Alpina/GT-Alpina-Condensed-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT-Alpina";
  src: url("./assets/fonts/GT-Alpina/GT-Alpina-Condensed-Bold-Italic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "mabry";
  src: url("./assets/fonts/mabry/mabry-bold-pro.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "mabry";
  src: url("./assets/fonts/mabry/mabry-regular-pro.ttf");
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  background: rgb(210, 214, 239);
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

figure {
  margin: 0;
}

#root {
  height: 100%;
  overflow: auto;
}

/* Effects */
.shimmer {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}
